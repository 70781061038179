#about {
  width: 100%;
  font-family: var(--primary-font);
}

#about h1 {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 10px;
}

#about p {
  font-size: 16px;
  color: var(--secondary-color);
  line-height: 1.6;
  margin-left: 10px;
}

.about-description {
  margin-left: 0px !important;
}

.content-wrapper-about {
  display: block;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

.about-columns {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.about-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.about-column a {
  text-decoration: none;
}

.about-item {
  display: flex;
  min-height: 80px;
}

.about-item p {
  margin-left: 10px;
  margin-bottom: 0;
  line-height: 1.5;
}

.about-item i {
  font-size: 20px;
  color: var(--link-color);
}

span {
  color: var(--primary-color);
}

/* Media Queries */
@media (max-width: 768px) {
  .about-columns {
    flex-direction: column;
    gap: 0px;
  }

  #about h1 {
    font-size: 1.2rem;
  }

  #about p {
    font-size: 14px;
  }

}

@media (max-width: 576px) {
  .about-column {
    gap: 0px;
  }

  #about h1 {
    font-size: 1rem;
  }

  #about p {
    font-size: 12px;
  }
}
