.navbar-container {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  justify-content: center;
}

.navbar-icon {
  position: fixed;
  top: 20px; 
  left: calc(50% - 600px + 25px);
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
  color: #333;
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  z-index: 1000;
}

.navbar-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.navbar-icon:hover {
  background-color: var(--link-color);
  color: white;
}

.navbar-icon.scrolled {
  transform: scale(1.2);
}

.navbar-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin: 0 auto;
  width: fit-content;
  font-family: var(--primary-font);
}

.navbar-nav-custom {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-item-custom {
  display: inline-block;
}

.nav-link-custom {
  text-decoration: none;
  padding: 10px 15px;
  font-size: 1rem;
  color: #333;
  transition: color 0.3s ease;
  border-radius: 10px;
}

.nav-link-custom:hover {
  color: var(--link-color);
}

.nav-link-custom.active {
  font-weight: bold;
  color: var(--link-color);
}

@media (max-width: 1200px) {
  .navbar-icon {
    display: none;
  }
}
