.projects {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 100px;
}
.projects h1 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 10px;
}

.all-projects-project {
    transition: transform 0.3s ease;
    overflow: hidden;
}

.all-projects-project:hover {
    transform: translateY(-5px);
}

.all-projects-project > div[data-aos] {
    transform: translateY(0);
}

.project-modal-container {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.project-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.project-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 100%;
}

.project-modal-iframe {
    width: 100%;
    min-width: 800px;
    height: 80vh;
    max-width: 100%;
    border: none;
    margin: 0;
    padding: 0;
}

.project-modal-slideshow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-modal-image {
    max-width: 80%;
    max-height: 80vh;
}

@media (max-width: 900px) {
    .all-projects-project {
        width: calc(50% - 20px);
    }
}

@media (max-width: 600px) {
    .projects {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    .all-projects-project {
        width: 100%;
        margin-bottom: 10px;
    }
}