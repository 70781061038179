@import "/src/data/styles.css";

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #383636;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(0, 0, 0);
}

.page-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 25px;
  box-sizing: border-box;
  position: relative;
  background-color: #f8f8f8;
  overflow-x: hidden;
  overflow-y: auto;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh; 
  overflow: hidden;
}

.title {
  color: var(--primary-color);
  font-family: var(--secondary-font);
  font-size: 25px;
  font-weight: 700;
  width: 70%;
}

.subtitle {
  padding-top: 10px;
  color: var(--secondary-color);
  font-size: 16px;
  line-height: 28px;
  width: 70%;
  margin-top: 25px;
}

.cv-about-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: 45vh;
}

.about {
  flex: 0 0 70%;
  padding-right: 20px;
  box-sizing: border-box;
}

.cv {
  flex: 0 0 30%;
  box-sizing: border-box;
}

/* Media Queries */
@media (max-width: 1270px) {
  .content-wrapper {
    max-width: 90%;
    padding: 0 5%;
  }

  .cv-about-container {
    flex-direction: column;
    height: auto;
  }
}

@media (max-width: 1024px) {
  .content-wrapper {
    max-width: 980px;
  }

  .page-content {
    padding: 0 15px;
  }

  .title, .subtitle {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .content-wrapper {
    max-width: 90%;
    margin: 0 auto;
    padding: 0 10px;
  }

  .title, .subtitle {
    width: 100%;
    font-size: 1.2rem;
  }

  .cv-about-container {
    flex-direction: column;
  }

  .about {
    flex: 0 0 100%;
    padding-right: 0;
  }

  .cv {
    flex: 0 0 100%;
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .content-wrapper {
    padding: 0 5px;
  }

  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .content-wrapper {
    display: grid;
    width: 100%;
  }
}