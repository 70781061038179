.project {
    mix-blend-mode: normal;
    border-radius: 20px;
    opacity: 0.8;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
  
  .project a {
    text-decoration: none;
  }
  
  .project:hover {
    background: #fcfcfc;
    opacity: 1;
  }
  
  .project:hover .project-link,
  .project:hover .project-demo-link {
    color: var(--link-color);
  }
  
  .project-container {
    flex-grow: 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .project-logo {
    width: 30px;
  }
  
  .project-logo img {
    width: 100%;
  }
  
  .project-title {
    font-family: var(--secondary-font);
    color: var(--primary-color);
    font-size: 16px;
    padding-top: 15px;
    margin-bottom: 1em;
    font-weight: 700;
  }
  
  .project-description {
    color: var(--secondary-color);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
  
  .project-links {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    padding-top: 20px;
  }
  
  .project-link,
  .project-demo-link {
    display: inline-flex;
    align-items: center;
    color: var(--secondary-color);
    font-size: 12px;
    white-space: nowrap;
  }
  
  .project-link-icon {
    margin-right: 10px;
    display: inline-block;
  }
  
  .project-link-text {
    display: inline-block;
    font-weight: 700;
  }
  
  @media (max-width: 600px) {
    .project {
      height: 150px;
      padding: 15px;
  }
  .project-logo {
    width: 25px;
}

.project-title {
    font-size: 12px;
    margin-bottom: 0.5em;
}

.project-description {
    display: none;
}

.project-links {
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    align-items: flex-start;
}

.project-link,
.project-demo-link {
    font-size: 10px;
}
}