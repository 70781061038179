.content-wrapper-cv {
  display: block;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 50px;
  margin-left: 50px;
  box-sizing: border-box;
  font-family: var(--primary-font);
}

.cv-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
}

.cv-icon {
  color: var(--secondary-color);
  font-size: 24px;
  margin-right: 10px;
}

.cv-title {
  font-size: 24px;
  font-weight: bold;
  color: var(--secondary-color);
  text-align: left;
}

.cv-body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.work {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
}

.cv-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 0px 15px 5px 0px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.cv-details {
  display: flex;
  flex-direction: column;
}

.cv-job-title {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-color);
}

.cv-subtitle {
  font-size: 14px;
  color: var(--secondary-color);
}

.cv-duration {
  font-size: 12px;
  color: var(--tertiary-color);
}

/* Media Queries */
@media (max-width: 768px) {
  .cv-header {
    align-items: flex-start;
  }

  .cv-title {
    font-size: 1.2rem;
  }

  .cv-body {
    width: 100%;
  }

  .work {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 576px) {
  .cv-title {
    font-size: 1rem;
  }

  .cv-image {
    margin-bottom: 15px;
  }

  .content-wrapper-cv {
    padding: 0px 20px 0px 20px;
  }

  .cv-job-title {
    font-size: 14px;
  }

  .cv-subtitle, .cv-duration {
    font-size: 12px;
  }

  .content-wrapper-cv {
    margin-left: 0px;
  }
}
