.footer {
  border-top: 2px solid var(--tertiary-color);
  padding: 20px 0;
  text-align: center;
  font-family: var(--primary-font);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 0;
  text-align: center;
}

/* Media Queries */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 15px 0;
  }
}