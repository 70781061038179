:root {
	/* ------- colors ------- */
	--primary-color: #27272a;
	--secondary-color: #65656d;
	--tertiary-color: #acacb4;
	--quaternary-color: #e4e4e7;
	--link-color: #76AFF4;
	--link-color-hover: #5a8ce6;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "DM Sans", sans-serif;
	--secondary-font: "DM Sans", sans-serif;
	/* --------------------- */
}