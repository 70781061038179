.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
  font-family: var(--primary-font);
  margin-top: 100px;
}

.contact h1 {
  color: var(--primary-color);
  font-weight: 700;
  margin-bottom: 1rem;
}

.contact p {
  color: var(--secondary-color);
  font-size: 1rem;
  margin: 1.5rem 0;
}

.contact a {
  text-decoration: none;
  font-size: 1rem;
  color: var(--secondary-color);
  transition: color 0.3s ease;
}

.contact a:hover {
  color: var(--primary-color);
}

.contact-container {
  width: auto;
  min-width: 500px;
  max-width: 1000px;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: height 0.5s ease, width 0.5s ease;
}

.social-links-contact {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.form-header textarea {
  max-width: 100%;
  min-width: 100%;
  max-height: 300px;
  min-height: 100px;
  box-sizing: border-box;
}

.form-header {
  margin-bottom: 1rem;
}

#contact-text {
  margin: 1rem 0;
  font-size: 1.2rem;
}

.form-header label {
  display: block;
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.form-header input,
.form-header textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.form-header input:focus,
.form-header textarea:focus {
  border-color: var(--link-color);
  outline: none;
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

.submit-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--link-color);
  color: #fff;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: var(--link-color-hover);
}

.submit-icon {
  font-size: 1.2rem;
}

.buttom-contact-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.buttom-contact-container h2 {
  font-size: 1.4rem;
  color: var(--secondary-color);
  font-weight: 500;
  margin-bottom: 1.5rem;
}

/* Media Queries */
@media (max-width: 768px) {
  .contact-container {
    width: 90%;
    min-width: 200px;
  }

  .contact h1 {
    font-size: 1.5rem;
  }

  .contact {
    margin-top: 50px;
  }

  .contact p {
    display: none;
  }

  .submit-button {
    width: 100%;
    padding: 0.5rem;
    justify-content: center;
  }

  .buttom-contact-container h2 {
    font-size: 1.25rem;
  }

  .buttom-contact-container p {
    font-size: 0.875rem;
  }
}
