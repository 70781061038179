.description {
  width: 100%;
  font-family: var(--primary-font);
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box;
}

.text-content {
  width: 60%;
  text-align: left;
}

.text-left, .text-right {
  flex: 1;
  max-width: 60%;
  margin: 0 5%;
  opacity: 0;
  transform: translateY(50px);
  animation: slideUp 1s forwards ease-out;
}

.text-left {
  animation-delay: 0.5s; 
}

.text-right {
  animation-delay: 1s;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 20px;
}

h4 {
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--secondary-color);
  line-height: 1.5;
}

.image-content {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  margin-right: 0;
}

.profile-pic {
  width: 250px;
  height: 400px;
  border-radius: 5%;
  object-fit: cover;
}

.social-links {
  margin-top: 20px;
}

.social-links a {
  margin-right: 15px;
  color: var(--secondary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--primary-color);
}

.name {
  color: var(--link-color);
  font-weight: 600;
}

/* Keyframe animations */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media queries */  

@media (max-width: 1024px) {
  .text-content, .image-content {
    width: 100%;
  }

  .description {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  h1 {
    font-size: 1.8rem;
  }

  h4 {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .profile-pic {
    width: 200px;
    height: 320px;
  }

  h1 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 0.9rem;
  }

  .text-left, .text-right {
    margin: 0 3%;
    max-width: 100%;
    text-align: center;
  }

  .social-links a {
    margin-right: 10px;
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .description {
    padding: 10px;
    align-items: center;
    flex-direction: column;
  }

  .image-content {
    order: -1;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  .profile-pic {
    width: 180px;
    height: 270px;
  }

  .text-right {
    margin-top: 40px;
  }

  .text-content {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }

  h1 {
    font-size: 1.2rem;
    color: #f8f8f8;
  }

  h4 {
    font-size: 0.85rem;
  }

  .social-links {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }

  .social-links a {
    margin: 0 8px;
    font-size: 1.2rem;
  }
}

